import i18n from '@/i18n/setup';

import { ITEM_TYPES } from './features';

export const PROPERTIES = {
	CATEGORY: 'category',
	SELECTED_ITEMS: 'selectedItems',
	WEBSITE_URL: 'websiteUrl',
};

export const DEFAULT_VALUES = {
	[PROPERTIES.CATEGORY]: '',
	[PROPERTIES.WEBSITE_URL]: '',
	[PROPERTIES.SELECTED_ITEMS]: [
		ITEM_TYPES.BLOG,
		ITEM_TYPES.FOOTER_CONTACT,
		ITEM_TYPES.INSTAGRAM,
		ITEM_TYPES.VIDEO,
	],
};

export const GENERATOR_STEPS = [
	{
		subtitle: i18n.t('wizard.selectCategory.subtitle'),
		title: i18n.t('wizard.selectCategory.title'),
		component: 'WizardCategoryInput',
		property: PROPERTIES.CATEGORY,
		isNavigationFixedBottom: false,
		hasSkipOption: true,
		hasContinueOption: false,
		logEventOnContinue: {
			eventName: 'qa_wizard.website_type.select_category',
			propertyName: 'category_name',
		},
	},
	{
		subtitle: i18n.t('wizard.selectFeatures.subtitle'),
		title: i18n.t('wizard.selectFeatures.title'),
		component: 'WizardFeaturesSelect',
		property: PROPERTIES.SELECTED_ITEMS,
		isNavigationFixedBottom: true,
		hasSkipOption: false,
		hasContinueOption: true,
	},
	{
		subtitle: i18n.t('wizard.selectWebsite.subtitle'),
		title: i18n.t('wizard.selectWebsite.title'),
		component: 'WizardPreview',
		isNavigationFixedBottom: false,
		hasSkipOption: false,
		hasContinueOption: false,
	},
];

export const IMPORTER_STEPS = [
	{
		subtitle: i18n.t('wizard.importWebsite.subtitle'),
		title: i18n.t('wizard.importWebsite.title'),
		component: 'ImporterInput',
		property: PROPERTIES.WEBSITE_URL,
		isNavigationFixedBottom: false,
		hasSkipOption: false,
		hasContinueOption: true,
		logEventOnContinue: {
			eventName: 'qa_wizard.website_importer.enter_domain',
			propertyName: 'domain',
		},
	},
	{
		subtitle: i18n.t('wizard.selectImportedWebsite.subtitle'),
		title: i18n.t('wizard.selectImportedWebsite.title'),
		component: 'WizardPreview',
		isNavigationFixedBottom: false,
		hasSkipOption: false,
		hasContinueOption: false,
	},
];
