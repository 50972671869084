import {
	BLOG_LIST_PAGE_TYPE,
	ECOMMERCE_PAGE_TYPE,
} from '@/constants';
import i18n from '@/i18n/setup';

/*
 *  Data structure:
 *  GENERATOR LOGIC
 *  // ----------------------
 *  FEATURE SELECT UI INFO
 *
 *  Items ordered in generation order
 */

export const ITEM_TYPES = {
	HEADLINE: 'headline',
	VIDEO: 'video',
	ABOUT: 'about',
	INSTAGRAM: 'instagram',
	IMAGE_GALLERY: 'image-gallery',
	SUBSCRIBE_FORM: 'subscribe-form',
	MAP: 'map',
	FOOTER: 'footer',
	FOOTER_CONTACT: 'footer-contact',
	ONLINE_STORE: 'online-store',
	BLOG: 'blog',
};

export const ADD_BLOCK_PAGES = {
	HEADLINE: 'headlines-page',
	VIDEO: 'video-page',
	ABOUT: 'about-page',
	INSTAGRAM: 'instagram-feed',
	IMAGE_GALLERY: 'images-page',
	SUBSCRIBE_FORM: 'subscribe-form',
	MAP: 'map-page',
	FOOTER: 'footer-page',
};

export const ADD_CONDITIONS = {
	ALWAYS: 'ALWAYS',
	IF_SELECTED: 'IF_SELECTED',
};

export const GENERATOR_ITEMS = [
	{
		type: ITEM_TYPES.HEADLINE,
		addCondition: ADD_CONDITIONS.ALWAYS,
		addBlockTemplatePage: ADD_BLOCK_PAGES.HEADLINE,
	},
	{
		type: ITEM_TYPES.VIDEO,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addBlockTemplatePage: ADD_BLOCK_PAGES.VIDEO,
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.videoTitle'),
		subtitle: i18n.t('wizard.selectFeatures.videoSubtitle'),
		image: 'wizard-video.png',
		selectOrder: 6,
	},
	{
		type: ITEM_TYPES.ABOUT,
		addCondition: ADD_CONDITIONS.ALWAYS,
		addBlockTemplatePage: ADD_BLOCK_PAGES.ABOUT,
	},
	{
		type: ITEM_TYPES.INSTAGRAM,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addBlockTemplatePage: ADD_BLOCK_PAGES.INSTAGRAM,
		fallback: {
			type: ITEM_TYPES.IMAGE_GALLERY,
			addBlockTemplatePage: ADD_BLOCK_PAGES.IMAGE_GALLERY,
		},
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.instagramTitle'),
		subtitle: i18n.t('wizard.selectFeatures.instagramSubtitle'),
		image: 'wizard-instagram.png',
		selectOrder: 5,
	},
	{
		type: ITEM_TYPES.SUBSCRIBE_FORM,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addBlockTemplatePage: ADD_BLOCK_PAGES.SUBSCRIBE_FORM,
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.subscriptionFormTitle'),
		subtitle: i18n.t('wizard.selectFeatures.subscriptionFormSubtitle'),
		image: 'wizard-subscribe-form.png',
		selectOrder: 4,
	},
	{
		type: ITEM_TYPES.MAP,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addBlockTemplatePage: ADD_BLOCK_PAGES.MAP,
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.mapTitle'),
		subtitle: i18n.t('wizard.selectFeatures.mapSubtitle'),
		image: 'wizard-map.png',
		selectOrder: 5,
	},
	{
		type: ITEM_TYPES.FOOTER_CONTACT,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addBlockTemplatePage: ADD_BLOCK_PAGES.FOOTER,
		fallback: {
			type: ITEM_TYPES.FOOTER,
			addBlockTemplatePage: ADD_BLOCK_PAGES.FOOTER,
		},
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.contactFormTitle'),
		subtitle: i18n.t('wizard.selectFeatures.contactFormSubtitle'),
		image: 'wizard-contact-form.png',
		selectOrder: 3,
	},
	{
		type: ITEM_TYPES.ONLINE_STORE,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addPageType: ECOMMERCE_PAGE_TYPE,
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.onlineStoreTitle'),
		subtitle: i18n.t('wizard.selectFeatures.onlineStoreSubtitle'),
		image: 'wizard-online-store.png',
		selectOrder: 1,
	},
	{
		type: ITEM_TYPES.BLOG,
		addCondition: ADD_CONDITIONS.IF_SELECTED,
		addPageType: BLOG_LIST_PAGE_TYPE,
		// --------------------------------
		title: i18n.t('wizard.selectFeatures.blogTitle'),
		subtitle: i18n.t('wizard.selectFeatures.blogSubtitle'),
		image: 'wizard-blog.png',
		selectOrder: 2,
	},
];
