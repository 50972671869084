export const LOADER_TYPES = {
	DEFAULT: 'default',
	GENERATOR: 'generator',
};

export const WIZARD_TYPE_GENERATOR = 'generator';
export const WIZARD_TYPE_IMPORTER = 'importer';

export const GENERATED_SITE_NAME = 'Your generated site';
export const IMPORTED_SITE_NAME = 'Your imported site';

export const GENERATED_SITE_TEMPLATE_ID = 'generated';
export const IMPORTED_SITE_TEMPLATE_ID = 'website-importer';
