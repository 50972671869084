import {
	computed,
	reactive,
	ref,
} from '@vue/composition-api';

import EventLogApi from '@/api/EventLogApi';
import { DEFAULT_VALUES } from '@/pages/wizard/data/steps';

export const DEFAULT_STEP = 0;

export const useStep = (props) => {
	const currentStep = ref(DEFAULT_STEP);
	const loaderType = ref('');
	const stepState = reactive({ ...props.stepState });

	const currentStepData = computed(() => props.steps[currentStep.value]);

	const currentStepComponent = computed(() => currentStepData.value.component);

	const currentStepPropertyValue = computed(() => stepState[currentStepData.value.property]);

	const canGoBack = computed(() => !!props.steps[currentStep.value - 1]);

	const canContinue = computed(() => (
		!!props.steps[currentStep.value + 1]
		&& currentStepData.value.hasContinueOption
		&& !!currentStepPropertyValue.value
	));

	const canSkip = computed(() => !!currentStepData.value.hasSkipOption);

	const subtitle = computed(() => currentStepData.value.subtitle);

	const title = computed(() => currentStepData.value.title);

	const errorTitle = computed(() => currentStepData.value.errorTitle);

	const errorSubtitle = computed(() => currentStepData.value.errorSubtitle);

	const isNavigationFixedBottom = computed(() => currentStepData.value.isNavigationFixedBottom);

	const setLoaderType = (type) => {
		loaderType.value = type;
	};

	const setCurrentStepPropertyValue = (value) => {
		stepState[currentStepData.value.property] = value;
	};

	const setActiveStep = (value, stateValues) => {
		const isGoingForward = value > currentStep.value;
		const stepNavigationGuard = props.steps[value].stepGuard;

		if (stepNavigationGuard && !stepNavigationGuard({ ...stateValues })) {
			const nextStepValue = isGoingForward ? +1 : -1;

			setActiveStep(value + nextStepValue);

			return;
		}

		currentStep.value = value;
	};

	const goBackward = () => {
		setActiveStep(currentStep.value - 1);
	};

	const goForward = () => {
		const { logEventOnContinue } = currentStepData.value;

		if (logEventOnContinue) {
			EventLogApi.logEvent({
				eventName: logEventOnContinue.eventName,
				eventProperties: { [logEventOnContinue.propertyName]: currentStepPropertyValue.value },
			});
		}

		setActiveStep(currentStep.value + 1);
	};

	const skip = () => {
		const { logEventOnContinue } = currentStepData.value;

		if (logEventOnContinue) {
			EventLogApi.logEvent({
				eventName: logEventOnContinue.eventName,
				eventProperties: { skipped: true },
			});
		}

		setCurrentStepPropertyValue(DEFAULT_VALUES[currentStepData.value.property]);
		setActiveStep(currentStep.value + 1);
	};

	return {
		stepState,
		loaderType,
		currentStep,
		currentStepData,
		currentStepComponent,
		currentStepPropertyValue,
		canContinue,
		canGoBack,
		canSkip,
		subtitle,
		title,
		errorTitle,
		errorSubtitle,
		isNavigationFixedBottom,
		setLoaderType,
		setActiveStep,
		setCurrentStepPropertyValue,
		goBackward,
		goForward,
		skip,
	};
};
