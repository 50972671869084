<template>
	<div
		class="wizard-base"
		:class="{
			'wizard-base--full-height': loaderType,
			'wizard-base--padding-bottom': isNavigationFixedBottom
		}"
	>
		<div class="wizard-base__header">
			<ZyroSvg
				class="wizard-base__logo"
				name="logo"
			/>
		</div>
		<div
			v-if="!showError"
			class="wizard-base__content"
			:class="{ 'wizard-base__content--hidden': loaderType }"
		>
			<p
				v-if="subtitle"
				class="wizard-base__subtitle z-subheading z-subheading--spaced"
				v-text="subtitle"
			/>
			<h4
				v-if="title"
				class="wizard-base__title z-h4"
				v-text="title"
			/>
			<slot />
			<div
				class="wizard-base__navigation"
				:class="{ 'wizard-base__navigation--fixed-bottom': isNavigationFixedBottom }"
			>
				<ZyroButton
					v-if="isBackButtonVisible"
					icon-left="chevron-left"
					data-qa="wizard-btn-back"
					@click="onGoBackClick"
				>
					{{ $t('common.goBack') }}
				</ZyroButton>
				<div class="wizard-base__navigation-forward-wrapper">
					<ZyroButton
						v-if="showSkip"
						class="wizard-base__skip"
						theme="outline"
						data-qa="wizard-btn-skip"
						@click="$emit('skip')"
						v-text="$t('common.skip')"
					/>
					<ZyroButton
						v-if="showContinue"
						class="wizard-base__continue"
						:theme="continueButtonTheme"
						data-qa="wizard-btn-continue"
						@click="$emit('continue')"
						v-text="$t('common.continue')"
					/>
				</div>
			</div>
		</div>
		<div
			v-else
			class="wizard-base__error-wrapper"
		>
			<p
				v-if="error.subtitle"
				class="wizard-base__subtitle z-subheading z-subheading--spaced"
				v-text="error.subtitle"
			/>
			<h4
				v-if="error.title"
				class="wizard-base__title z-h4"
				v-text="error.title"
			/>
			<ZyroButton
				theme="primary"
				class="wizard-base__error-button"
				@click="$emit('try-again')"
				v-text="$t('common.tryAgain')"
			/>
		</div>
		<div class="wizard-base__loader-screen-container">
			<ZyroLoader
				v-show="showDefaultLoader"
				:style="loaderStyles"
				class="wizard-base__loader"
			/>
			<Transition name="scale-up">
				<WizardLoader
					v-show="showGeneratorLoader"
					:loader-message="loaderMessage"
				/>
			</Transition>
		</div>
	</div>
</template>

<script>
import WizardLoader from '@/pages/wizard/WizardLoader.vue';
import { LOADER_TYPES } from '@/pages/wizard/data/constants';
import { useRedirects } from '@/use/useRedirects';

export default {
	components: { WizardLoader },
	props: {
		title: {
			type: String,
			default: '',
		},
		subtitle: {
			type: String,
			default: '',
		},
		error: {
			type: Object,
			default: null,
		},
		showBack: {
			type: Boolean,
			required: true,
		},
		isDisabled: {
			type: Boolean,
			default: false,
		},
		currentStep: {
			type: Number,
			required: true,
		},
		showSkip: {
			type: Boolean,
			required: true,
		},
		showContinue: {
			type: Boolean,
			required: true,
		},
		isNavigationFixedBottom: {
			type: Boolean,
			default: false,
		},
		loaderType: {
			type: String,
			default: '',
		},
		loaderMessage: {
			type: String,
			required: true,
		},
	},
	setup() {
		const { redirectToWWWWizard } = useRedirects();

		return { redirectToWWWWizard };
	},
	computed: {
		showError: ({ error }) => error !== null,
		isBackButtonVisible: ({
			showBack,
			currentStep,
		}) => showBack || currentStep === 0,
		showDefaultLoader: ({ loaderType }) => loaderType === LOADER_TYPES.DEFAULT,
		showGeneratorLoader: ({ loaderType }) => loaderType === LOADER_TYPES.GENERATOR,
		continueButtonTheme: ({ isDisabled }) => (isDisabled ? 'outline' : 'primary'),
		loaderStyles: () => ({
			'--default-color': 'transparent',
			'--size': '42px',
			'--default-border': '2px',
		}),
	},
	methods: {
		onGoBackClick() {
			if (this.currentStep === 0) {
				this.redirectToWWWWizard();
			} else {
				this.$emit('back');
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.wizard-base {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	min-height: 100vh;
	overflow: hidden;

	/*
	*  Even when loader is shown, we want to render content,
	*  so videos and lazyloaded images are instantly here after loading is done
	*  This style is dynamically added on loading, to prevent possible scroll from content
	*/
	&--full-height {
		max-height: 100vh;
	}

	&--padding-bottom {
		padding-bottom: 100px;
	}

	&__header {
		z-index: 1;
		display: flex;
		align-items: center;
		width: 100%;
		padding: 23px 40px;
		background: $light;
		border-bottom: 1px solid $grey-300;
	}

	&__content {
		position: relative;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
		width: 100%;
		padding: 48px 20px 0 20px;

		&--hidden {
			opacity: 0;
		}
	}

	&__logo {
		width: 61px;
		height: 30px;
	}

	&__subtitle {
		margin-bottom: 8px;
		text-align: center;
	}

	&__title {
		text-align: center;
	}

	&__hint {
		margin-top: 32px;
	}

	&__navigation {
		z-index: 1;
		display: flex;
		justify-content: space-between;
		width: 100%;
		padding: 32px 30px;
		margin-top: auto;
		background: $light;

		&--fixed-bottom {
			position: fixed;
			bottom: 0;
			box-shadow: $box-shadow;
		}
	}

	&__navigation-forward-wrapper {
		display: flex;
		margin-left: auto;
	}

	&__skip {
		margin-right: 10px;
	}

	&__error-wrapper {
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	&__error-button {
		margin-top: 38px;
	}

	&__loader-screen-container {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
		overflow: hidden;
		pointer-events: none;
	}

	&__default-loader {
		color: white;
		background: $grey-600;
	}
}

// Animations
.scale-up {
	&-leave-active {
		transition: all 0.8s ease;
	}

	&-leave-to {
		opacity: 0;
		transform: scale(1.4);
	}
}
</style>
