<template>
	<div class="generator-loader">
		<div class="generator-loader__image-wrapper">
			<div
				v-for="index in $options.LOADER_IMAGES_COUNT"
				:key="`image-${index}`"
				:class="`generator-loader__image  generator-loader__image--${index}`"
				:style="{
					backgroundImage: `url(
						${require(`../../assets/images/wizard-loader-${index}.png`)}
					)`
				}"
			/>
			<div class="generator-loader__backdrop" />
			<div
				class="generator-loader__loader"
			>
				<ZyroLoader
					:style="loaderStyles"
					class="generator-loader__loader-spinner"
				/>
				<div class="generator-loader__title z-subheading z-subheading--spaced">
					{{ loaderMessage }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
const LOADER_IMAGES_COUNT = 5;

export default {
	props: {
		loaderMessage: {
			type: String,
			required: true,
		},
	},
	computed: {
		loaderStyles() {
			return {
				'--default-color': 'transparent',
				'--size': '42px',
				'--default-border': '2px',
			};
		},
	},
	LOADER_IMAGES_COUNT,
};
</script>

<style lang="scss" scoped>
.generator-loader {
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	overflow: hidden;
	color: $light;
	background: $dark;

	&__image-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		animation: scaleDown 0.6s ease;
	}

	&__backdrop {
		@include backdrop;
	}

	&__title {
		margin: 0 0 0 16px;
	}

	&__loader {
		position: absolute;
		z-index: 2;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 100%;
	}

	&__loader-spinner {
		margin: 0;
	}

	&__image {
		position: absolute;
		bottom: 0;
		width: 50%;
		height: 1336px;
		background-repeat: repeat-y;
		background-position: center center;
		background-size: cover;

		&--3 {
			left: 52%;
			display: block;
		}

		@media screen and (min-width: $media-mobile) {
			width: 45%;

			&:nth-of-type(odd) {
				bottom: 0;
				animation: moveDown linear 20s infinite alternate, fadeIn 1s linear;
			}

			&:nth-of-type(even) {
				top: 0;
				animation: moveUp linear 20s infinite alternate, fadeIn 1s linear;
			}

			&--1 {
				left: 26%;
			}

			&--2 {
				left: -20%;
			}

			&--3 {
				left: 26%;
			}

			&--4 {
				left: 72%;
				display: block;
			}

			&--5 {
				left: 26%;
				display: block;
			}
		}

		@media screen and (min-width: 1250px) {
			width: 32%;

			&--1 {
				left: -26%;
				display: block;
			}

			&--2 {
				left: 4%;
			}

			&--3 {
				left: 34%;
			}

			&--4 {
				left: 64%;
			}

			&--5 {
				left: 94%;
				display: block;
			}
		}
	}
}

@keyframes scaleDown {
	from {
		transform: scale(1.4);
	}

	to {
		transform: scale(1);
	}
}

@keyframes moveDown {
	0% { background-position: center 0; }
	100% { background-position: center 1336px; }
}

@keyframes moveUp {
	0% { background-position: center 0; }
	100% { background-position: center -1336px; }
}

@keyframes fadeIn {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
</style>
