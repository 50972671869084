import { render, staticRenderFns } from "./WizardLoader.vue?vue&type=template&id=459472fa&scoped=true&"
import script from "./WizardLoader.vue?vue&type=script&lang=js&"
export * from "./WizardLoader.vue?vue&type=script&lang=js&"
import style0 from "./WizardLoader.vue?vue&type=style&index=0&id=459472fa&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459472fa",
  null
  
)

export default component.exports